import ItemsSection from "../components/clothesComp/ItemSection";
import ItemDisplay from "../components/clothesComp/ItemDisplay";

import shirt from "../images/clothesImages/man/clothes/shirt.jpg";
import turtleNeck from "../images/clothesImages/man/clothes/turtleneck.jpg";
import kartijan from "../images/clothesImages/man/clothes/kartijan.jpg";
import blos from "../images/clothesImages/man/clothes/blos.jpg";
import pejame from "../images/clothesImages/man/clothes/pajamas.jpg";
import jacket from "../images/clothesImages/man/clothes/jacket.jpg";
import qamis from "../images/clothesImages/man/clothes/qamis.jpg";
import pentron from "../images/clothesImages/man/clothes/panteron.jpeg";
import tracksuit from "../images/clothesImages/man/clothes/tracksuit.jpg";
import short from "../images/clothesImages/man/clothes/short.jpg";
import jacketOpen from "../images/clothesImages/man/clothes/jacketOpen.jpg";
import suit from "../images/clothesImages/man/clothes/suit.jpg";

import filat from "../images/clothesImages/man/shoes/filat.jpg";
import sport from "../images/clothesImages/man/shoes/sport.jpg";
import puta from "../images/clothesImages/man/shoes/puta.jpg";
import qonder from "../images/clothesImages/man/shoes/qunder.jpg";
import herwel from "../images/clothesImages/man/shoes/herwel.jpg";
import neal from "../images/clothesImages/man/shoes/neal.jpg";
import kale from "../images/clothesImages/man/shoes/kale.jpg";

import watch from "../images/clothesImages/man/watch/watch.jpg";
import glasses from "../images/clothesImages/man/watch/glasse.jpg";
import wallet from "../images/clothesImages/man/watch/wallet.jpg";
import braclet from "../images/clothesImages/man/watch/braclet.jpg";
import belt from "../images/clothesImages/man/watch/belt.jpg";
import hat from "../images/clothesImages/man/watch/hat.jpg";
import tie from "../images/clothesImages/man/watch/tie.jpg";

import backPack from "../images/clothesImages/man/backpack/backpack.jpg";
import sportBag from "../images/clothesImages/man/backpack/sportBag.jpg";
import postachi from "../images/clothesImages/man/backpack/postachi.jpg";
import travelBag from "../images/clothesImages/man/backpack/travelBag.jpg";
import laptopBag from "../images/clothesImages/man/backpack/laptopBag.jpg";

import pefume from "../images/clothesImages/man/cosmetics/perfume.jpg";
import shampo from "../images/clothesImages/man/cosmetics/sahmpo.jpg";
import shower from "../images/clothesImages/man/cosmetics/showerCream.jpg";
import condom from "../images/clothesImages/man/cosmetics/condom.jpg";
import shaveMaterial from "../images/clothesImages/man/cosmetics/shaveMaterial.jpg";
import oral from "../images/clothesImages/man/cosmetics/oral.jpg";
import sunscreen from "../images/clothesImages/man/cosmetics/sunscreen.jpg";
import skincare from "../images/clothesImages/man/cosmetics/skincare.jpg";
import skincaretoo from "../images/clothesImages/man/cosmetics/skincaretoo.jpg";

import underWear from "../images/clothesImages/man/short/shorts.jpeg";
import socks from "../images/clothesImages/man/short/socks.jpg";
import tanktop from "../images/clothesImages/man/short/tanktop.jpg";
import pejamas from "../images/clothesImages/man/short/pejamas.jpeg";

import sportClothes from "../images/clothesImages/man/sport/sportClothes.jpg";
import sportShoes from "../images/clothesImages/man/sport/sportShoes.jpeg";
import sporttracksuit from "../images/clothesImages/man/sport/sporttracksuit.jpg";
import sportBagSportSection from "../images/clothesImages/man/sport/sportBag.jpg";
import jogClothes from "../images/clothesImages/man/sport/jogClothes.jpg";
import jogShoes from "../images/clothesImages/man/sport/jogShoes.jpg";
import sportbloz from "../images/clothesImages/man/sport/sportbloz.jpg";
import equipment from "../images/clothesImages/man/sport/equipment.jpg";
import protein from "../images/clothesImages/man/sport/protein.jpg";

export default function MenClothes() {
  return (
    <main className="col-lg-10 col-8">
      <h2 className="display-4 mb-5">ستایلێن زەلاما</h2>

      <ItemsSection sectName="جلوبەرگ" scrollLink="menCloth">
        <ItemDisplay
          imgSrc={shirt}
          name="تیشێرت"
          linkSrc="https://www.trendyol.com/erkek-t-shirt-x-g2-c73"
        />
        <ItemDisplay
          imgSrc={turtleNeck}
          name="بلوزێن ب حەفک"
          linkSrc="https://www.trendyol.com/erkek-kazak-x-g2-c1092"
        />
        <ItemDisplay
          imgSrc={kartijan}
          name="کارتیجان"
          linkSrc="https://www.trendyol.com/erkek-hirka-x-g2-c1066"
        />
        <ItemDisplay
          imgSrc={blos}
          name="بلوز"
          linkSrc="https://www.trendyol.com/erkek-sweatshirt-x-g2-c1179"
        />
        <ItemDisplay
          imgSrc={pejame}
          name="بێجامە"
          linkSrc="https://www.trendyol.com/sr/erkek-pijama-x-g2-c1143?q=pijama&qt=erkek%20pijama&st=erkek%2520p"
        />
        <ItemDisplay
          imgSrc={jacket}
          name="چاکیت"
          linkSrc="https://www.trendyol.com/erkek-mont-x-g2-c118"
        />
        <ItemDisplay
          imgSrc={qamis}
          name="قەمیس"
          linkSrc="https://www.trendyol.com/erkek-gomlek-x-g2-c75"
        />
        <ItemDisplay
          imgSrc={pentron}
          name="پەنتەرون"
          linkSrc="https://www.trendyol.com/erkek-pantolon-x-g2-c70"
        />
        <ItemDisplay
          imgSrc={tracksuit}
          name="تەرەکسوت"
          linkSrc="https://www.trendyol.com/erkek-esofman-x-g2-c1049"
        />
        <ItemDisplay
          imgSrc={short}
          name="شورت"
          linkSrc="https://www.trendyol.com/erkek-sort-x-g2-c119"
        />
        <ItemDisplay
          imgSrc={jacketOpen}
          name="چاکیتێن ڤەکری"
          linkSrc="https://www.trendyol.com/sr/erkek-ceket-yelek-x-g2-c104153"
        />
        <ItemDisplay
          imgSrc={suit}
          name="قات"
          linkSrc="https://www.trendyol.com/erkek-takim-elbise-x-g2-c78"
        />
      </ItemsSection>
      <ItemsSection sectName="پێلاڤ" scrollLink="menShoes">
        <ItemDisplay
          imgSrc={filat}
          name="پێلاڤێن فلات"
          linkSrc="https://www.trendyol.com/erkek-gunluk-ayakkabi-x-g2-c1352"
        />
        <ItemDisplay
          imgSrc={sport}
          name="پێلاڤێن وەرزشی"
          linkSrc="https://www.trendyol.com/erkek-spor-ayakkabi-x-g2-c109"
        />
        <ItemDisplay
          imgSrc={puta}
          name="پوتە"
          linkSrc="https://www.trendyol.com/erkek-bot-ve-cizme-x-g2-c1351"
        />
        <ItemDisplay
          imgSrc={qonder}
          name="قوندەرە"
          linkSrc="https://www.trendyol.com/erkek-klasik-ayakkabi-x-g2-c101421"
        />
        <ItemDisplay
          imgSrc={herwel}
          name="پێلاڤێن هەروێلێ"
          linkSrc="https://www.trendyol.com/erkek-kosu-ayakkabisi-x-g2-c101426"
        />
        <ItemDisplay
          imgSrc={neal}
          name="نەعال"
          linkSrc="https://www.trendyol.com/erkek-sandalet-ve-terlik-x-g2-c112"
        />
        <ItemDisplay
          imgSrc={kale}
          name="کالە"
          linkSrc="https://www.trendyol.com/sr?q=erkek%20loafer%20%26%20oxford&qt=erkek%20loafer%20%26%20oxford&st=erkek%20loafer%20%26%20oxford&os=1"
        />
      </ItemsSection>
      <ItemsSection sectName="دەمژمێر و بەرچافک" scrollLink="menWatchAndGlass">
        <ItemDisplay
          imgSrc={watch}
          name="دەمژمێر"
          linkSrc="https://www.trendyol.com/erkek-saat-x-g2-c34"
        />

        <ItemDisplay
          imgSrc={glasses}
          name="بەرچافک"
          linkSrc="https://www.trendyol.com/erkek-gunes-gozlugu-x-g2-c105"
        />
        <ItemDisplay
          imgSrc={wallet}
          name="جزدان"
          linkSrc="https://www.trendyol.com/erkek-cuzdan-kartlik-x-g2-c1353"
        />
        <ItemDisplay
          imgSrc={braclet}
          name="بازن"
          linkSrc="https://www.trendyol.com/erkek-bileklik-x-g2-c101"
        />
        <ItemDisplay
          imgSrc={belt}
          name="کەمەر"
          linkSrc="https://www.trendyol.com/erkek-kemer-x-g2-c1093"
        />
        <ItemDisplay
          imgSrc={hat}
          name="کولاڤ"
          linkSrc="https://www.trendyol.com/erkek-sapka-x-g2-c1181"
        />
        <ItemDisplay
          imgSrc={tie}
          name="ریبات"
          linkSrc="https://www.trendyol.com/erkek-kravat-x-g2-c1101"
        />
      </ItemsSection>
      <ItemsSection sectName="چانتێن پشتێ" scrollLink="menBags">
        <ItemDisplay
          imgSrc={backPack}
          name="چانتێن پشتێ"
          linkSrc="https://www.trendyol.com/erkek-sirt-cantasi-x-g2-c115"
        />
        <ItemDisplay
          imgSrc={sportBag}
          name="چانتێن وەرزشێ"
          linkSrc="https://www.trendyol.com/erkek-spor-cantasi-x-g2-c1174"
        />
        <ItemDisplay
          imgSrc={postachi}
          name="چانتێن پوستەچی"
          linkSrc="https://www.trendyol.com/erkek-postaci-canta-x-g2-c1152"
        />
        <ItemDisplay
          imgSrc={travelBag}
          name="چانتێن گەشتا"
          linkSrc="https://www.trendyol.com/erkek-valiz-x-g2-c1202"
        />
        <ItemDisplay
          imgSrc={laptopBag}
          name="چانتێن لاپتوبی"
          linkSrc="https://www.trendyol.com/erkek-laptop-cantasi-x-g2-c1107"
        />
      </ItemsSection>
      <ItemsSection sectName="کوزماتیک و چاڤدێریا لەشی" scrollLink="menPerfume">
        <ItemDisplay
          imgSrc={pefume}
          name="گولاڤ"
          linkSrc="https://www.trendyol.com/erkek-parfum-x-g2-c86"
        />
        <ItemDisplay
          imgSrc={shampo}
          name="شامپو"
          linkSrc="https://www.trendyol.com/erkek-sac-bakimi-x-g2-c87"
        />
        <ItemDisplay
          imgSrc={shower}
          name="کرێمێن سەرشویێ"
          linkSrc="https://www.trendyol.com/sr/dus-jeli-ve-kremleri-x-c101401?q=erkek%20dus%20jeli&qt=erkek%20dus%20jeli&st=erkek%20dus%20jeli"
        />
        <ItemDisplay
          imgSrc={condom}
          name="ساخلەمیا سێکسی"
          linkSrc="https://www.trendyol.com/cinsel-saglik-x-c101408"
        />
        <ItemDisplay
          imgSrc={shaveMaterial}
          name="کەرستێن تراشینێ"
          linkSrc="https://www.trendyol.com/erkek-islak-tiras-x-g2-c103106"
        />
        <ItemDisplay
          imgSrc={oral}
          name="چاڤدانا دەڤ و ددانا"
          linkSrc="https://www.trendyol.com/sr/erkek-agiz-bakim-x-g2-c101396"
        />
        <ItemDisplay
          imgSrc={sunscreen}
          name="کرێما تاڤا روژێ"
          linkSrc="https://www.trendyol.com/sr?q=erkek%20g%C3%BCne%C5%9F%20%C3%BCr%C3%BCnleri&qt=erkek%20g%C3%BCne%C5%9F%20%C3%BCr%C3%BCnleri&st=erkek%20g%C3%BCne%C5%9F%20%C3%BCr%C3%BCnleri&os=1"
        />
        <ItemDisplay
          imgSrc={skincare}
          name="چاڤدانا پیستی"
          linkSrc="https://www.trendyol.com/erkek-cilt-bakimi-x-g2-c85"
        />
        <ItemDisplay
          imgSrc={skincaretoo}
          name="چاڤدانا لەشی"
          linkSrc="https://www.trendyol.com/erkek-vucut-bakimi-x-g2-c1204"
        />
      </ItemsSection>
      <ItemsSection sectName="جل و بەرگێن تایبەت" scrollLink="menShorts">
        <ItemDisplay
          imgSrc={underWear}
          name="شورت"
          linkSrc="https://www.trendyol.com/erkek-boxer-x-g2-c61"
        />

        <ItemDisplay
          imgSrc={socks}
          name="گورە"
          linkSrc="https://www.trendyol.com/erkek-corap-x-g2-c1038"
        />
        <ItemDisplay
          imgSrc={tanktop}
          name="فانێلە"
          linkSrc="https://www.trendyol.com/erkek-atlet-x-g2-c1004"
        />
        <ItemDisplay
          imgSrc={pejamas}
          name="بێجامە"
          linkSrc="https://www.trendyol.com/erkek-pijama-x-g2-c1143"
        />
      </ItemsSection>
      <ItemsSection sectName="وەرزشی و هەروێلێ" scrollLink="menSport">
        <ItemDisplay
          imgSrc={sportClothes}
          name="جلکێن وەرزشی"
          linkSrc="https://www.trendyol.com/erkek-spor-giyim-x-g2-c101447"
        />

        <ItemDisplay
          imgSrc={sportShoes}
          name="پێلاڤێن وەرزشی"
          linkSrc="https://www.trendyol.com/erkek-spor-ayakkabi-x-g2-c109"
        />
        <ItemDisplay
          imgSrc={sporttracksuit}
          name="تەرەکسوت"
          linkSrc="https://www.trendyol.com/erkek-esofman-x-g2-c1049"
        />
        <ItemDisplay
          imgSrc={sportBagSportSection}
          name="چانتێن وەرزشی"
          linkSrc="https://www.trendyol.com/erkek-spor-cantasi-x-g2-c1174"
        />
        <ItemDisplay
          imgSrc={jogClothes}
          name="جلوبەرگێن هەروێلێ"
          linkSrc="https://www.trendyol.com/erkek-giyim-x-g2-c82"
        />
        <ItemDisplay
          imgSrc={jogShoes}
          name="پێلاڤێن هەروێلێ"
          linkSrc="https://www.trendyol.com/erkek-outdoor-ayakkabi-x-g2-c1128"
        />
        <ItemDisplay
          imgSrc={sportbloz}
          name="بلوز"
          linkSrc="https://www.trendyol.com/erkek-spor-sweatshirt-x-g2-c101456"
        />
        <ItemDisplay
          imgSrc={equipment}
          name="ئامیرێن وەرزشی"
          linkSrc="https://www.trendyol.com/erkek-spor-aletleri-x-g2-c104192"
        />
        <ItemDisplay
          imgSrc={protein}
          name="پروتین و فیتامین"
          linkSrc="https://www.trendyol.com/sporcu-besini-supplementler-x-c105131"
        />
      </ItemsSection>
    </main>
  );
}
