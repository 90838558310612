export default function MubaLocation() {
  return (
    <div className="container-xxl text-center">
      <h2 dir="rtl" className="mb-4">
        ناڤونیشانێن ئوفیسێ
      </h2>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d671.0164611548313!2d42.979317563471554!3d36.8685682432483!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40088dad7fba1d53%3A0x648303e2f17c5ba4!2sMuba%20Bazar!5e0!3m2!1sen!2siq!4v1709073974298!5m2!1sen!2siq"
        width="100%"
        height="450"
        title="muba_map"
        style={{ border: "0" }}
        allowFullScreen=""
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      />
    </div>
  );
}
