import { useEffect, useState } from "react";

import LiraIMG from "../images/icons/lira.svg";
import dolarIMG from "../images/icons/dollar.svg";

export default function Exchange() {
  const [lira, setLira] = useState(0);
  const [dolar, setDolar] = useState(0);

  let tlToUSD = sessionStorage.getItem("TLValue") || 30;

  useEffect(() => {
    const n = lira / tlToUSD;
    const lwhole = Math.trunc(n);
    const f = n - lwhole;

    if (lira === "" || parseFloat(lira) === 0) {
      setDolar("");
    } else if (!isNaN(n)) {
      if (f <= 0.5) {
        const anotherInnerRes = lwhole + 0.5;
        setDolar(anotherInnerRes);
      } else {
        const innerRes = Math.round(n);
        setDolar(innerRes);
      }
    } else setDolar("");
  }, [lira]);

  function onPriceChange(e) {
    setLira(e);
  }

  return (
    <div className="container pt-5 pb-2">
      <h2 className="text-center mb-4">پارە گوهور</h2>
      <ExchangeCompSmall
        curValue={lira}
        setValue={onPriceChange}
        dolar={dolar}
      />
      <p className="text-center mt-4 text-body-secondary">
        بو زانین بوهایێ لیرێ مە ل دیف بازارێ تورکی یە نە ل دیف وێ یە یا ئەنترنێت
        د دەت و هەر چەند رۆژا بوها د هێنە گوهرین
      </p>
    </div>
  );
}

function ExchangeCompSmall({ curValue, setValue, dolar }) {
  return (
    <section className="col-lg-8 col-12 mx-auto">
      <div className="mx-lg-5 mx-2">
        <div className="d-flex align-items-center">
          <img
            className="me-md-4 me-2"
            src={LiraIMG}
            width="45"
            height="45"
            alt="lira"
            draggable={false}
          />
          <input
            type="text"
            value={curValue}
            onChange={(event) => setValue(event.target.value)}
            className="form-control form-control-lg border-3   rounded-0"
          />
        </div>
        <div className="row my-4 align-items-center text-center fs-5 ">
          <hr className="col-md-4 col-2 my-0" />
          <span className="col">لیرە</span>
          <hr className="col-md-1 col my-0" />
          <span className="col">دولار</span>
          <hr className="col-md-4 col-2 my-0" />
        </div>
        <div className="d-flex align-items-center">
          <img
            className="me-md-4 me-2"
            src={dolarIMG}
            width="45"
            height="45"
            alt="dolar"
            draggable={false}
          />
          <input
            value={dolar}
            disabled
            className="form-control form-control-lg border-3  rounded-0 "
            type="text"
          />
        </div>
      </div>
    </section>
  );
}
