export default function VideoTutorials() {
  const VideoComp = ({ videoLink, videoTitle }) => {
    return (
      <div className="col-lg-4 col-md-6 col-12 my-2">
        <iframe
          className="w-100"
          style={{ aspectRatio: "2/1" }}
          src={`https://www.youtube-nocookie.com/embed/${videoLink}?si=rUxGxomFgot0PTnU&amp;controls=0`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        />
        <p className="my-2 fs-5 text-center" dir="rtl">
          {videoTitle}
        </p>
      </div>
    );
  };

  return (
    <div className="container my-4 ">
      <h2 className="text-center mb-4" dir="rtl">
        ڤیدیو و فێرکاری
      </h2>
      <section className="row">
        <VideoComp
          videoLink={"wqVsn7LirIU"}
          videoTitle={"موبا بازار بالعربي"}
        />
        <VideoComp videoLink={"wDxu8sSV-ws"} videoTitle={"بوچ موبا بازار"} />
        <VideoComp
          videoLink={"Qxw5Y6AZUOM"}
          videoTitle={"چەوانیا بازارکرنێ ژ ترێندیولی"}
        />
      </section>
    </div>
  );
}
