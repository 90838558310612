import { Link } from "react-router-dom";
import Logo from "../images/logo.webp";
import { useState } from "react";
import BurgerMenu from "../images/icons/burgerMenu.svg";

export default function SiteHeader() {
  const [navView, setNavView] = useState(false);

  const SmallNav = () => (
    <section
      dir="rtl"
      className=" container text-white d-md-none d-flex flex-column gap-4 pt-4"
    >
      <Link to="/" className="custom-text-hover-orange text-decoration-none">
        سەرەکی
      </Link>
      <Link
        to="/brands"
        className="custom-text-hover-orange text-decoration-none"
      >
        براند
      </Link>
      <Link
        to="/aboutus"
        className="custom-text-hover-orange text-decoration-none"
      >
        سەبارەت مە
      </Link>
    </section>
  );

  return (
    <header className="bg-custom-blue py-4 ">
      <nav className="container d-flex justify-content-between align-items-center">
        <section>
          <img src={Logo} alt="MUBALOGO" />
        </section>

        <section
          className="text-white d-md-flex d-none gap-4 "
          style={{ direction: "rtl" }}
        >
          <Link
            to="/"
            className="mb-0 custom-text-hover-orange text-decoration-none"
          >
            سەرەکی
          </Link>
          <Link
            to="/brands"
            className="mb-0 custom-text-hover-orange text-decoration-none"
          >
            براند
          </Link>
          <Link
            to="/aboutus"
            className="mb-0 custom-text-hover-orange text-decoration-none"
          >
            سەبارەت مە
          </Link>
        </section>

        <section className="d-md-none d-block">
          <button onClick={() => setNavView(!navView)} className="btn p-0">
            <img width="24" height="24" src={BurgerMenu} alt="=" />
          </button>
        </section>
      </nav>
      {navView ? <SmallNav /> : null}
    </header>
  );
}
