import ProductSection from "../components/productSection";
import BrandsSlice from "../components/brandsSlide";
import AboutMuba from "../components/aboutMuba";
import VideoTutorials from "../components/videoTutorials";
import MubaLocation from "../components/mubaLocation";
import Exchange from "../components/exchange";

export default function Home() {
  return (
    <main>
      <ProductSection />
      <BrandsSlice />
      <AboutMuba />
      <Exchange />
      <VideoTutorials />
      <MubaLocation />
    </main>
  );
}
