import { useEffect, useState } from "react";

import LiraIMG from "../images/icons/liraOrange.svg";
import dolarIMG from "../images/icons/dollarOrange.svg";

export default function ExchangeFooter() {
  const [lira, setLira] = useState(0);
  const [dolar, setDolar] = useState(0);

  const tlToUSD = sessionStorage.getItem("TLValue") || 30;

  useEffect(() => {
    const n = lira / tlToUSD;
    const lwhole = Math.trunc(n);
    const f = n - lwhole;

    if (lira === "" || parseFloat(lira) === 0) {
      setDolar("");
    } else if (!isNaN(n)) {
      if (f <= 0.5) {
        const anotherInnerRes = lwhole + 0.5;
        setDolar(anotherInnerRes);
      } else {
        const innerRes = Math.round(n);
        setDolar(innerRes);
      }
    } else setDolar("");
  }, [lira]);

  function onPriceChange(e) {
    setLira(e);
  }

  return (
    <div className="container py-3 pb-5">
      <h4 className="text-center mb-1 text-custom-orange">پارە گوهور</h4>
      <ExchangeCompSmall
        curValue={lira}
        setValue={onPriceChange}
        dolar={dolar}
      />
    </div>
  );
}

function ExchangeCompSmall({ curValue, setValue, dolar }) {
  return (
    <section className="mx-auto col-12">
      <div className="mx-lg-5 mx-2 row">
        <div className="d-flex align-items-center col-sm-5 col-12">
          <img
            className="me-md-4 me-2"
            src={LiraIMG}
            width="30"
            height="30"
            alt="lira"
            draggable={false}
          />
          <input
            type="text"
            value={curValue}
            onChange={(event) => setValue(event.target.value)}
            className="form-control border-3   rounded-0"
          />
        </div>
        <div className="my-4 flex-column d-flex justify-content-between text-center fs-5 text-white col">
          <hr className="my-0" />
          <span className="col">لیرە</span>

          <span className="col">دولار</span>

          <hr className=" my-0" />
        </div>
        <div className="d-flex align-items-center col-sm-5 col-12">
          <img
            className="me-2 d-sm-none d-block"
            src={dolarIMG}
            width="30"
            height="30"
            alt="dolar"
            draggable={false}
          />
          <input
            value={dolar}
            disabled
            className="form-control border-3 rounded-0 text-muted"
            type="text"
          />
          <img
            className="ms-md-3 ms-2 d-sm-block d-none"
            src={dolarIMG}
            width="30"
            height="30"
            alt="dolar"
            draggable={false}
          />
        </div>
      </div>
    </section>
  );
}
