import ItemsSection from "../components/clothesComp/ItemSection";
import ItemDisplay from "../components/clothesComp/ItemDisplay";

import bedle from "../images/clothesImages/kids/baby/bedle.jpg";
import kidsbadi from "../images/clothesImages/kids/baby/kidsbedle.jpg";
import seroubin from "../images/clothesImages/kids/baby/seroubin.jpg";
import birthclothes from "../images/clothesImages/kids/baby/birth.jpg";
import hefase from "../images/clothesImages/kids/baby/hefase.jpg";
import klinster from "../images/clothesImages/kids/baby/klinster.jpg";
import kidsleep from "../images/clothesImages/kids/baby/sleep.jpg";
import kidfood from "../images/clothesImages/kids/baby/kidfood.jpg";

import kidSport from "../images/clothesImages/kids/clothes/sport.jpg";
import kidshort from "../images/clothesImages/kids/clothes/kidshort.jpg";
import kidblos from "../images/clothesImages/kids/clothes/kidblos.jpg";
import pajamas from "../images/clothesImages/kids/clothes/pejamas.jpeg";
import shirt from "../images/clothesImages/kids/clothes/shirt.jpeg";
import kidshirt from "../images/clothesImages/kids/clothes/kidshirt.jpg";
import dress from "../images/clothesImages/kids/clothes/dress.jpg";

import water from "../images/clothesImages/kids/shoes/water.jpg";
import kidFlat from "../images/clothesImages/kids/shoes/kidflat.jpg";
import kidneal from "../images/clothesImages/kids/shoes/kidneal.jpg";
import sportShoes from "../images/clothesImages/kids/shoes/sport.jpg";

import babybag from "../images/clothesImages/kids/kidscare/babybag.jpg";
import banyokids from "../images/clothesImages/kids/kidscare/banyokids.jpg";
import hefaze from "../images/clothesImages/kids/kidscare/hefaze.jpg";
import sleepseat from "../images/clothesImages/kids/kidscare/sleepseat.jpg";
import sunscreencream from "../images/clothesImages/kids/kidscare/sunscreencream.jpg";
import wettowel from "../images/clothesImages/kids/kidscare/wettowel.jpg";

import babyfood from "../images/clothesImages/kids/nutrition/babyfood.jpg";
import fooditem from "../images/clothesImages/kids/nutrition/fooditem.jpg";
import korsik from "../images/clothesImages/kids/nutrition/korsik.jpg";
import milkers from "../images/clothesImages/kids/nutrition/milkers.jpg";
import plasticbottle from "../images/clothesImages/kids/nutrition/plasticbottle.jpg";
import sersing from "../images/clothesImages/kids/nutrition/sersing.jpg";
import shirshan from "../images/clothesImages/kids/nutrition/shirshan.jpg";

import ajalok from "../images/clothesImages/kids/protect/ajalok.jpg";
import arabana from "../images/clothesImages/kids/protect/arabana.jpg";
import camera from "../images/clothesImages/kids/protect/camera.jpg";
import hijoliketransport from "../images/clothesImages/kids/protect/hijoliketransport.jpg";
import hisjolike from "../images/clothesImages/kids/protect/hisjolike.jpg";
import pecholk from "../images/clothesImages/kids/protect/pecholk.jpg";
import takht from "../images/clothesImages/kids/protect/takht.jpg";

import avakirne from "../images/clothesImages/kids/toys/avakirne.jpg";
import bahce from "../images/clothesImages/kids/toys/bahce.jpg";
import box from "../images/clothesImages/kids/toys/box.jpg";
import ferkar from "../images/clothesImages/kids/toys/ferkar.jpg";
import hirjo from "../images/clothesImages/kids/toys/hirjo.jpg";
import hzry from "../images/clothesImages/kids/toys/hzry.jpg";
import likey from "../images/clothesImages/kids/toys/likey.jpg";
import payskil from "../images/clothesImages/kids/toys/payskil.jpg";
import skooter from "../images/clothesImages/kids/toys/skooter.jpg";

export default function KidsClothes() {
  return (
    <main className="col-lg-10 col-8">
      <h2 className="display-4 mb-5">زاروک</h2>
      <ItemsSection sectName="بیبیک" scrollLink="kidsBaby">
        <ItemDisplay
          imgSrc={bedle}
          name="بەدلە"
          linkSrc="https://www.trendyol.com/cocuk-tulum-x-g3-c58"
        />

        <ItemDisplay
          imgSrc={kidsbadi}
          name="بادی یێ زاروکان"
          linkSrc="https://www.trendyol.com/bebek-body-zibin-x-c104566"
        />
        <ItemDisplay
          imgSrc={seroubin}
          name="جلکێن سەروبن"
          linkSrc="https://www.trendyol.com/bebek-takimlari-x-c103727"
        />
        <ItemDisplay
          imgSrc={birthclothes}
          name="جلکێن بونێ"
          linkSrc="https://www.trendyol.com/hastane-cikisi-x-c104159"
        />
        <ItemDisplay
          imgSrc={hefase}
          name="حەفازە"
          linkSrc="https://www.trendyol.com/sr?q=alt%20a%C3%A7ma%20minderi&qt=alt%20a%C3%A7ma%20minderi&st=alt%2520a%25C3%25A7ma%2520minderi&os=1"
        />
        <ItemDisplay
          imgSrc={klinster}
          name="کلینسێن تەڕ"
          linkSrc="https://www.trendyol.com/sr/islak-mendil-x-c101411?q=bebek%20%C4%B1slak%20mendil&qt=bebek%20%C4%B1slak%20mendil&st=bebek%20%C4%B1slak%20mendil"
        />
        <ItemDisplay
          imgSrc={kidsleep}
          name="جهێ خەوێ"
          linkSrc="https://www.trendyol.com/sr?q=bebek%20uyku%20seti&qt=bebek%20uyku%20seti&st=bebek%20uyku%20seti&os=1&sk=1"
        />
        <ItemDisplay
          imgSrc={kidfood}
          name="خارنێن زاروکان"
          linkSrc="https://www.trendyol.com/sr?q=bebek%20mamas%C4%B1&qt=bebek%20mamas%C4%B1&st=bebek%20mamas%C4%B1&os=1"
        />
      </ItemsSection>
      <ItemsSection sectName="جلوبەرگ" scrollLink="kidsClothes">
        <ItemDisplay
          imgSrc={dress}
          name="کراس"
          linkSrc="https://www.trendyol.com/cocuk-elbise-x-g3-c56"
        />
        <ItemDisplay
          imgSrc={kidshirt}
          name="تیشێرت"
          linkSrc="https://www.trendyol.com/cocuk-t-shirt-x-g3-c73"
        />
        <ItemDisplay
          imgSrc={shirt}
          name="قەمیس"
          linkSrc="https://www.trendyol.com/cocuk-gomlek-x-g3-c75"
        />
        <ItemDisplay
          imgSrc={kidSport}
          name="تەرەکسوتێن وەرزشی"
          linkSrc="https://www.trendyol.com/cocuk-esofman-takimi-x-g3-c101452"
        />
        <ItemDisplay
          imgSrc={pajamas}
          name="بێجامە"
          linkSrc="https://www.trendyol.com/cocuk-pijama-takimi-x-g3-c101496"
        />
        <ItemDisplay
          imgSrc={kidblos}
          name="بلوز"
          linkSrc="https://www.trendyol.com/cocuk-sweatshirt-x-g3-c1179"
        />
        <ItemDisplay
          imgSrc={kidshort}
          name="شورت"
          linkSrc="https://www.trendyol.com/cocuk-sort-x-g3-c119"
        />
      </ItemsSection>
      <ItemsSection sectName="پێلاڤ" scrollLink="kidsshoes">
        <ItemDisplay
          imgSrc={sportShoes}
          name="پێلاڤێن وەزشی"
          linkSrc="https://www.trendyol.com/cocuk-spor-ayakkabi-x-g3-c109"
        />
        <ItemDisplay
          imgSrc={kidFlat}
          name="پێلاڤێن فلات"
          linkSrc="https://www.trendyol.com/cocuk-gunluk-ayakkabi-x-g3-c1352"
        />
        <ItemDisplay
          imgSrc={kidneal}
          name="شحاتە و نەعال"
          linkSrc="https://www.trendyol.com/cocuk-sandalet-ve-terlik-x-g3-c112"
        />
        <ItemDisplay
          imgSrc={water}
          name="پێلاڤێن ئاڤێ"
          linkSrc="https://www.trendyol.com/cocuk-deniz-ayakkabisi-x-g3-c103687"
        />
      </ItemsSection>
      <ItemsSection sectName="چاڤدانا بیبیکا" scrollLink="kidsCare">
        <ItemDisplay
          imgSrc={babybag}
          name="چانتێن بیبیکا"
          linkSrc="https://www.trendyol.com/sr/cocuk-bebek-bakim-cantasi-x-g3-c1014?qt=bebek%20%C3%A7antas%C4%B1&st=bebek%20%C3%A7antas%C4%B1"
        />
        <ItemDisplay
          imgSrc={wettowel}
          name="کلینسێن تەر و خاولی"
          linkSrc="https://www.trendyol.com/sr/islak-mendil-x-c101411?q=bebek%20%C4%B1slak%20mendil&qt=bebekevi%20%C4%B1slak%20mendil&st=bebek%2520is"
        />
        <ItemDisplay
          imgSrc={hefaze}
          name="حەفازە"
          linkSrc="https://www.trendyol.com/sr?q=bebek%20bezi&qt=bebek%20bezi&st=bebek%2520b&os=1"
        />
        <ItemDisplay
          imgSrc={banyokids}
          name="بانیو یێن زاروکان"
          linkSrc="https://www.trendyol.com/sr?q=bebek%20k%C3%BCveti&qt=bebek%20k%C3%BCveti&st=bebek%2520k&os=1"
        />
        <ItemDisplay
          imgSrc={sleepseat}
          name="سێتێ خەوێ"
          linkSrc="https://www.trendyol.com/sr/cocuk-alt-acma-minderi-x-g3-c103767?qt=alt%20a%C3%A7ma%20minderi&st=bebek%2520al"
        />
        <ItemDisplay
          imgSrc={sunscreencream}
          name="پاراستن ژ تاڤێ"
          linkSrc="https://www.trendyol.com/sr?q=%C3%A7ocuk%20g%C3%BCne%C5%9F%20urunleri&qt=%C3%A7ocuk%20g%C3%BCne%C5%9F%20urunleri&st=%C3%A7ocuk%20g%C3%BCne%C5%9F%20urunleri&os=1"
        />
      </ItemsSection>
      <ItemsSection sectName="کەل و پەلێن خوارنێ" scrollLink="kidsFeed">
        <ItemDisplay
          imgSrc={shirshan}
          name="شیردانکێن شیشەی"
          linkSrc="https://www.trendyol.com/biberon-emzik-x-c103755"
        />
        <ItemDisplay
          imgSrc={babyfood}
          name="خوارنێن بیبیکا"
          linkSrc="https://www.trendyol.com/sr?q=bebek%20mamas%C4%B1&qt=bebek%20mamas%C4%B1&st=bebek%20mamas%C4%B1&os=1"
        />
        <ItemDisplay
          imgSrc={plasticbottle}
          name="دولکێن زاروکان"
          linkSrc="https://www.trendyol.com/alistirma-bardaklari-x-c103756"
        />
        <ItemDisplay
          imgSrc={fooditem}
          name="کەل وپەلێن خواردنێ"
          linkSrc="https://www.trendyol.com/yemek-setleri-x-c103766?pi=2"
        />
        <ItemDisplay
          imgSrc={korsik}
          name="کورسیکێن بلند"
          linkSrc="https://www.trendyol.com/mama-sandalyesi-x-c1111"
        />
        <ItemDisplay
          imgSrc={sersing}
          name="سەرسینگ"
          linkSrc="https://www.trendyol.com/gogus-pedleri-koruyucular-x-c103759"
        />
        <ItemDisplay
          imgSrc={milkers}
          name="کێشەرێن شیری"
          linkSrc="https://www.trendyol.com/gogus-pompalari-x-c103760"
        />
      </ItemsSection>
      <ItemsSection sectName="گێراندن و سلامەتی" scrollLink="kidsProtect">
        <ItemDisplay
          imgSrc={ajalok}
          name="عەرەبانک"
          linkSrc="https://www.trendyol.com/bebek-arabasi-puset-x-c103735"
        />

        <ItemDisplay
          imgSrc={arabana}
          name="هژیلوک وڤەگوهاستن"
          linkSrc="https://www.trendyol.com/ana-kucagi-x-c103731"
        />
        <ItemDisplay
          imgSrc={camera}
          name="تەختێ زاروکان"
          linkSrc="https://www.trendyol.com/park-yatak-ve-oyun-parki-x-c103710"
        />
        <ItemDisplay
          imgSrc={hijoliketransport}
          name="هژیلوکێ بیبیکا"
          linkSrc="https://www.trendyol.com/besik-x-c104511"
        />
        <ItemDisplay
          imgSrc={hisjolike}
          name="پێچولک"
          linkSrc="https://www.trendyol.com/portbebe-kanguru-sling-x-c103740"
        />
        <ItemDisplay
          imgSrc={pecholk}
          name="عجەلوک"
          linkSrc="https://www.trendyol.com/yurutec-x-c103741"
        />
        <ItemDisplay
          imgSrc={takht}
          name="کامیرە و چاڤدێر"
          linkSrc="https://www.trendyol.com/sr/cocuk-telsiz-kamera-dedektor-x-g3-c103747"
        />
      </ItemsSection>
      <ItemsSection sectName="یاری" scrollLink="kidsToys">
        <ItemDisplay
          imgSrc={ferkar}
          name="یاریێن فێرکاری"
          linkSrc="https://www.trendyol.com/ahsap-oyuncaklar-x-c103700"
        />
        <ItemDisplay
          imgSrc={avakirne}
          name="یاریێن ئاڤاکرنێ"
          linkSrc="https://www.trendyol.com/yapi-oyuncaklari-x-c108327"
        />
        <ItemDisplay
          imgSrc={likey}
          name="یاریێن لکەی"
          linkSrc="https://www.trendyol.com/sr?q=cocuk%20fig%C3%BCr%20oyuncaklar&qt=cocuk%20fig%C3%BCr%20oyuncaklar&st=cocuk%20fig%C3%BCr%20oyuncaklar&os=1&pi=2"
        />
        <ItemDisplay
          imgSrc={hirjo}
          name="هرچوک"
          linkSrc="https://www.trendyol.com/sr?q=Pelu%C5%9Flar&qt=Pelu%C5%9Flar&st=Pelu%C5%9Flar&os=1&pi=2"
        />
        <ItemDisplay
          imgSrc={hzry}
          name="یاریێن هزری"
          linkSrc="https://www.trendyol.com/sr?q=%C3%A7ocuk%20puzzle%20ve%20yapboz&qt=%C3%A7ocuk%20puzzle%20ve%20yapboz&st=%25C3%25A7ocuk%2520puzz&os=1"
        />
        <ItemDisplay
          imgSrc={payskil}
          name="ترومبێل و پایسگلێن کارەبێ"
          linkSrc="https://www.trendyol.com/sr?q=cocuk%20ak%C3%BCl%C3%BC%20ve%20pedall%C4%B1%20ara%C3%A7lar&qt=cocuk%20ak%C3%BCl%C3%BC%20ve%20pedall%C4%B1%20ara%C3%A7lar&st=cocuk%20ak%C3%BCl%C3%BC%20ve%20pedall%C4%B1%20ara%C3%A7lar&os=1&pi=2"
        />
        <ItemDisplay
          imgSrc={skooter}
          name="سکوتر"
          linkSrc="https://www.trendyol.com/bisiklet-scooter-paten-x-c104528"
        />
        <ItemDisplay
          imgSrc={box}
          name="یاریێن بوکس"
          linkSrc="https://www.trendyol.com/kutu-oyunlari-x-c103818"
        />

        <ItemDisplay
          imgSrc={bahce}
          name="یاریێن باخچەی"
          linkSrc="https://www.trendyol.com/bahce-oyuncaklari-x-c103691"
        />
      </ItemsSection>
    </main>
  );
}
