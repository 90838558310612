import trendyol from "../images/logos/trendyol.png";
import zara from "../images/logos/zara.png";
import uspolo from "../images/logos/uspolo.png";
import defacto from "../images/logos/defacto.png";
import flo from "../images/logos/flo.png";
import adidas from "../images/logos/adidas.png";
import koton from "../images/logos/koton.png";
import lacoste from "../images/logos/lacoste.png";
import mavi from "../images/logos/mavi.png";
import altinyildiz from "../images/logos/altinyildiz.png";
import { Link } from "react-router-dom";

export default function BrandsSlice() {
  const LogoCont = ({ imgSrc, linkSrc }) => {
    return (
      <div className="col-md-2 col-sm-3 col-4">
        <a
          className="d-block bg-white my-2 overflow-hidden"
          style={{ height: "100px", cursor: "pointer" }}
          href={linkSrc}
          target="_blank"
          rel="noreferrer"
        >
          <img
            className="object-fit-contain  custom-black-white-image overflow-hidden w-100 h-100"
            src={imgSrc}
            alt="img"
          ></img>
        </a>
      </div>
    );
  };

  return (
    <div className="bg-light py-2">
      <h2 className="py-4 text-center">براندێن ب ناڤودەنگ</h2>
      <section className="container mx-auto row px-0  align-items-center justify-content-center">
        <LogoCont imgSrc={trendyol} linkSrc="https://www.trendyol.com/" />
        <LogoCont imgSrc={zara} linkSrc="https://www.zara.com/tr/en/" />
        <LogoCont imgSrc={defacto} linkSrc="https://www.defacto.com.tr/" />
        <LogoCont imgSrc={flo} linkSrc="https://www.flo.com.tr/" />
        <LogoCont imgSrc={uspolo} linkSrc="https://tr.uspoloassn.com/" />
        <LogoCont
          imgSrc={altinyildiz}
          linkSrc="https://www.altinyildizclassics.com/"
        />
        <LogoCont imgSrc={adidas} linkSrc="https://www.adidas.com.tr/tr" />
        <LogoCont imgSrc={koton} linkSrc="https://www.koton.com/" />
        <LogoCont imgSrc={lacoste} linkSrc="https://www.lacoste.com.tr/" />
        <LogoCont imgSrc={mavi} linkSrc="https://www.mavicompany.com/tr" />
      </section>
      <section className="py-4 d-flex align-item-center justify-content-center">
        <Link
          to="/brands"
          className="border-top border-bottom fw-light fs-2 text-decoration-none custom-text-black-to-orange text-center m-0 px-3 py-1"
        >
          پتر براند
        </Link>
      </section>
    </div>
  );
}
