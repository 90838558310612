import { Link, useLocation } from "react-router-dom";

export default function SideNav() {
  const { pathname } = useLocation();

  const isMen = pathname === "/clothes/men" ? true : false;
  const isWomen = pathname === "/clothes/women" ? true : false;
  const isKids = pathname === "/clothes/kids" ? true : false;

  const StlizedLink = ({ cat, id, children }) => {
    return (
      <p className="my-2 ">
        <a
          className="custom-text-gray-to-orange text-decoration-none"
          href={`/clothes/${cat}#${id}`}
        >
          {children}
        </a>
      </p>
    );
  };

  return (
    <nav className="col-lg-2 col-4 ">
      <div className="position-sticky top-0 py-4">
        <section className="">
          <Link
            to="/clothes/men"
            className="text-decoration-none custom-text-black-to-orange h3"
          >
            زەلام
          </Link>

          <div
            className="overflow-hidden"
            style={{ maxHeight: isMen ? "500px" : "0", transition: "0.5s" }}
          >
            <StlizedLink cat="men" id="menCloth">
              جلوبەرگ
            </StlizedLink>
            <StlizedLink cat="men" id="menShoes">
              پێلاڤ
            </StlizedLink>
            <StlizedLink cat="men" id="menWatchAndGlass">
              دەمژمێر و بەرچافک
            </StlizedLink>
            <StlizedLink cat="men" id="menBags">
              چانتێن پشتێ
            </StlizedLink>
            <StlizedLink cat="men" id="menPerfume">
              کوزماتیک و چاڤدێریا لەشی
            </StlizedLink>
            <StlizedLink cat="men" id="menShorts">
              جل و بەرگێن تایبەت
            </StlizedLink>
            <StlizedLink cat="men" id="menSport">
              وەرزشی و هەروێلێ
            </StlizedLink>
          </div>
        </section>
        <hr className="col-6 border-dark border-opacity-50" />
        <section>
          <Link
            to="/clothes/women"
            className="text-decoration-none custom-text-black-to-orange h3"
          >
            ئافرەت
          </Link>
          <div
            className="overflow-hidden"
            style={{ maxHeight: isWomen ? "300px" : "0", transition: "0.5s" }}
          >
            <StlizedLink cat="women" id="womenCloth">
              جلکێن ئافرەتان
            </StlizedLink>
            <StlizedLink cat="women" id="womenShoes">
              پێلاڤ
            </StlizedLink>

            <StlizedLink cat="women" id="womenAccessories">
              ئێکسسوارات و بەرچافک
            </StlizedLink>
            <StlizedLink cat="women" id="womenBag">
              چانتە
            </StlizedLink>
            <StlizedLink cat="women" id="womenCosmetics">
              کوزماتیک و مێک ئاپ
            </StlizedLink>
            <StlizedLink cat="women" id="womenSport">
              وەرزشی
            </StlizedLink>
          </div>
        </section>
        <hr className="col-6 border-dark border-opacity-50" />
        <section>
          <Link
            to="/clothes/kids"
            className="text-decoration-none custom-text-black-to-orange h3"
          >
            زاروک
          </Link>
          <div
            className="overflow-hidden"
            style={{ maxHeight: isKids ? "250px" : "0", transition: "0.5s" }}
          >
            <StlizedLink cat="kids" id="kidsBaby">
              بیبیک
            </StlizedLink>
            <StlizedLink cat="kids" id="kidsClothes">
              جلوبەرگ
            </StlizedLink>
            <StlizedLink cat="kids" id="kidsshoes">
              پێلاڤ
            </StlizedLink>
            <StlizedLink cat="kids" id="kidsCare">
              چاڤدانا بیبیکا
            </StlizedLink>
            <StlizedLink cat="kids" id="kidsFeed">
              کەل و پەلێن خوارنێ
            </StlizedLink>
            <StlizedLink cat="kids" id="kidsProtect">
              گێراندن و سلامەتی
            </StlizedLink>
            <StlizedLink cat="kids" id="kidsToys">
              یاری
            </StlizedLink>
          </div>
        </section>
      </div>
    </nav>
  );
}
