import MubaLocation from "../components/mubaLocation";

import phone from "../images/icons/phone.svg";
import clock from "../images/icons/clock.svg";
import location from "../images/icons/location.svg";

export default function AboutUs() {
  return (
    <main dir="rtl" className="bg-light ">
      <div className="container py-5">
        {
          // <img className="bg-custom-blue rounded-5" src={MubaLogo} alt="mb" />
        }

        <div className="row mb-5">
          <section className="col-lg-8 col-12">
            <h2 className="mb-5 display-6 fw-bold text-custom-orange">
              موبا بازار
            </h2>
            <div className="fs-5">
              <p className="mb-4">
                هەر تشتەکێ تو بخازی تو د شێی ب دەست خو بێخی ژ وەلاتێ تورکیا ب
                رێکا موبا بازار د ناڤا هەمی وێبسایتێن تورکی دا بگەرە و تشتێ دلێ
                تە خاست ژێبگرە و بو مە لینکی بهنێرە ب زیترین دەم و ب بهایەکێ
                گونجای دێ بو تە گەهینینە دهوکێ، بهایێن گەهاندنێ ژ ( 2$ ) دولاران
                دەستپێ دکەن
              </p>
              <p className="mb-4">
                موبا بازار هاتیە دانان ژبەر بێزاریا خەلکی ژ مارکێن کوپی و بهایێن
                گران ل ناف بازارێن ناڤخویی دا موبا بازار نێزیکترین رێکە بو
                بازارێن تورکیا ومارکێن ئورجینال و بهایێن گونجای
              </p>
              <p className="mb-4">
                موبا بازار شانازیێ ب کارمەندێن خو دبەت یێن بسپور د بوارێ
                بازرگانیا ئونلاین دا . و جهێ باوەریا خەلکێ دهوکێ نە ژ بەر
                هەلبژارتنا وانا یا درۆست ژبو داخوازیێن بکران
              </p>
              <p className="text-start ms-5">- رێڤەبەریا موبا بازار</p>
            </div>
          </section>
          <section className="col-lg-4 col-12">
            <div className="my-4">
              <h4 className="mb-3">
                <img className="ms-3" src={phone} alt="phone" height="22" />
                بو پەیوەندیێ
              </h4>
              <p dir="rtl" className="mb-2 me-2">
                <span>ژمارە</span> {" : "}
                <span dir="ltr">+964 750 570 6060</span>
              </p>
              <p className="mb-2 me-2">
                ئیمێل
                {" : "}
                mubabazar@gmail.com
              </p>
            </div>
            <hr />

            <div className="my-4">
              <h4 className="mb-3">
                <img className="ms-3" src={clock} alt="clock" height="22" />
                دەمژمێرێن کاری
              </h4>
              <p className="mb-2 me-2">ژ شەمبیێ تا پێنج شەمبێ</p>
              <p className="mb-2 me-2">٢ ئێڤاری تا ٨ شەڤ</p>
            </div>
            <hr />
            <div className="my-4">
              <h4 className="mb-3">
                <img
                  className="ms-3"
                  src={location}
                  alt="location"
                  height="22"
                />
                ناڤ و نیشان
              </h4>
              <p className="mb-2 me-2">دهوک (دەورا هەسپا)</p>
              <p className="mb-2 me-2">جادا تانکا</p>
            </div>
          </section>
        </div>
        <MubaLocation />
      </div>
    </main>
  );
}
