export default function ItemDisplay({ imgSrc, name, linkSrc }) {
  return (
    <div className="col-xxl-2 col-lg-3 col-sm-4 col-6 py-3">
      <a
        className="d-block text-decoration-none text-black custom-image-child-zoom"
        href={linkSrc}
        target="_blank"
        rel="noreferrer"
      >
        <div
          className="overflow-hidden d-inline-block w-100 bg-light"
          style={{ aspectRatio: "3/4" }}
        >
          <img
            className="d-block w-100 h-100 object-fit-cover "
            loading="lazy"
            src={imgSrc}
            alt={`${name}-img`}
          />
        </div>
        <p className=" mt-3 fs-5 mb-0">{name}</p>
      </a>
    </div>
  );
}
