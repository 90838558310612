import { Link } from "react-router-dom";
import addax from "../images/logos/addax.png";
import adidas from "../images/logos/adidas.png";
import altinyildiz from "../images/logos/altinyildiz.png";
import bershka from "../images/logos/bershka.png";
import chiccy from "../images/logos/chiccy.png";
import colin from "../images/logos/colin.png";
import defacto from "../images/logos/defacto.png";
import denizbutik from "../images/logos/denizbutik.png";
import exxe from "../images/logos/exxe.png";
import flo from "../images/logos/flo.png";
import hotic from "../images/logos/hotic.png";
import hummel from "../images/logos/hummel.png";
import ipekyol from "../images/logos/ipekyol.png";
import journey from "../images/logos/journey.png";
import koton from "../images/logos/koton.png";
import lacoste from "../images/logos/lacoste.png";
import lcwaikiki from "../images/logos/lcwaikiki.png";
import lescon from "../images/logos/lescon.png";
import levis from "../images/logos/levis.png";
import ltb from "../images/logos/ltb.png";
import mango from "../images/logos/mango.png";
import marjin from "../images/logos/marjin.png";
import mavi from "../images/logos/mavi.png";
import mierre from "../images/logos/mierre.png";
import mudo from "../images/logos/mudo.png";
import newbalance from "../images/logos/newbalance.png";
import nike from "../images/logos/nike.png";
import penti from "../images/logos/penti.png";
import pierrecardin from "../images/logos/pierrecardin.png";
import pullnbear from "../images/logos/pullnbear.png";
import puma from "../images/logos/puma.png";
import sarar from "../images/logos/sarar.png";
import sateen from "../images/logos/sateen.png";
import siyahjnci from "../images/logos/siyahjnci.png";
import stamina from "../images/logos/stamina.png";
import tonnyblack from "../images/logos/tonnyblack.png";
import trendyol from "../images/logos/trendyol.png";
import trendyolmila from "../images/logos/trendyolmila.png";
import twist from "../images/logos/twist.png";
import uspolo from "../images/logos/uspolo.png";
import xlacati from "../images/logos/xlacati.png";
import zara from "../images/logos/zara.png";

export default function Brands() {
  const LogoCont = ({ imgSrc, linkSrc }) => {
    return (
      <div className="col-md-2 col-sm-3 col-4">
        <Link
          to={linkSrc}
          className="d-block bg-white my-2 overflow-hidden"
          style={{ height: "100px", cursor: "pointer" }}
        >
          <img
            className="object-fit-contain  custom-black-white-image overflow-hidden w-100 h-100"
            src={imgSrc}
            alt="img"
          ></img>
        </Link>
      </div>
    );
  };

  return (
    <main className="bg-light">
      <h2 dir="rtl" className="text-center py-5 m-0">
        براندێن ب ناڤودەنگ
      </h2>
      <section className="row container mx-auto pb-5">
        <LogoCont imgSrc={addax} linkSrc="https://www.addax.com.tr/" />
        <LogoCont imgSrc={adidas} linkSrc="https://www.adidas.com.tr/en" />
        <LogoCont
          imgSrc={altinyildiz}
          linkSrc="https://www.altinyildizclassics.com/"
        />
        <LogoCont imgSrc={bershka} linkSrc="https://www.bershka.com/tr/en/" />
        <LogoCont
          imgSrc={chiccy}
          linkSrc="https://www.trendyol.com/chiccy-x-b204"
        />
        <LogoCont imgSrc={colin} linkSrc="https://www.colins.com.tr/" />
        <LogoCont imgSrc={defacto} linkSrc="https://www.defacto.com.tr/" />
        <LogoCont imgSrc={denizbutik} linkSrc="https://www.denizbutik.com/" />
        <LogoCont imgSrc={exxe} linkSrc="https://www.exxeselection.com/" />
        <LogoCont imgSrc={flo} linkSrc="https://www.flo.com.tr/" />
        <LogoCont imgSrc={hotic} linkSrc="https://www.hotic.com.tr/" />
        <LogoCont imgSrc={hummel} linkSrc="https://hummel.com.tr/" />
        <LogoCont imgSrc={ipekyol} linkSrc="https://www.ipekyol.com.tr/" />
        <LogoCont imgSrc={journey} linkSrc="https://www.journey.com.tr/" />
        <LogoCont imgSrc={koton} linkSrc="https://www.koton.com/" />
        <LogoCont imgSrc={lacoste} linkSrc="https://www.lacoste.com.tr/" />
        <LogoCont
          imgSrc={lcwaikiki}
          linkSrc="https://www.lcwaikiki.com/tr-TR/TR"
        />
        <LogoCont imgSrc={lescon} linkSrc="https://www.lescon.com.tr/" />
        <LogoCont imgSrc={levis} linkSrc="https://www.levis.com.tr/" />
        <LogoCont imgSrc={ltb} linkSrc="https://www.ltbjeans.com/tr-TR" />
        <LogoCont imgSrc={mango} linkSrc="https://shop.mango.com/tr" />
        <LogoCont imgSrc={marjin} linkSrc="https://www.marjin.com.tr/" />
        <LogoCont imgSrc={mavi} linkSrc="https://www.mavicompany.com/tr" />
        <LogoCont
          imgSrc={mierre}
          linkSrc="https://www.trendyol.com/miorre?st=miorre&qt=Miorre"
        />
        <LogoCont imgSrc={mudo} linkSrc="https://www.mudo.com.tr/" />
        <LogoCont
          imgSrc={newbalance}
          linkSrc="https://www.newbalance.com.tr/"
        />
        <LogoCont imgSrc={nike} linkSrc="https://www.nike.com/tr" />
        <LogoCont imgSrc={penti} linkSrc="https://www.penti.com/en/" />
        <LogoCont
          imgSrc={pierrecardin}
          linkSrc="https://www.pierrecardin.com.tr/"
        />

        <LogoCont
          imgSrc={pullnbear}
          linkSrc="https://www.pullandbear.com/tr/"
        />
        <LogoCont imgSrc={puma} linkSrc="https://tr.puma.com/" />
        <LogoCont imgSrc={sarar} linkSrc="https://sarar.com/" />
        <LogoCont imgSrc={sateen} linkSrc="https://www.saten.com/en" />

        <LogoCont
          imgSrc={siyahjnci}
          linkSrc="https://www.trendyol.com/sr/siyah-inci-x-b102048?st=siyah%20inci&qt=Siyah%20%C4%B0nci"
        />
        <LogoCont
          imgSrc={stamina}
          linkSrc="https://www.trendyol.com/sr/stamina-x-b105217?st=Stamina&qt=Stamina"
        />
        <LogoCont
          imgSrc={tonnyblack}
          linkSrc="https://www.tonnyblack.com.tr/"
        />

        <LogoCont imgSrc={trendyol} linkSrc="https://www.trendyol.com/" />
        <LogoCont
          imgSrc={trendyolmila}
          linkSrc="https://www.trendyol-milla.com/"
        />
        <LogoCont imgSrc={twist} linkSrc="https://www.twist.com.tr/" />
        <LogoCont imgSrc={uspolo} linkSrc="https://tr.uspoloassn.com/" />
        <LogoCont
          imgSrc={xlacati}
          linkSrc="https://www.trendyol.com/sr/trend-alacati-stili-x-b102767?st=Ala%C3%A7at%C4%B1&qt=Trend%20Ala%C3%A7at%C4%B1%20Stili"
        />
        <LogoCont imgSrc={zara} linkSrc="https://www.zara.com/tr/en/" />
      </section>
    </main>
  );
}
