import { Outlet } from "react-router-dom";
import SideNav from "../components/clothesComp/clothesSideNav";

export default function Clothes() {
  return (
    <div dir="rtl" className="">
      <div className="container mt-4">
        <div className="row my-5">
          <SideNav />
          <Outlet />
        </div>
      </div>
    </div>
  );
}
