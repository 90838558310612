import { Link } from "react-router-dom";
import sadFAce from "../images/icons/ErrorsadFace.svg";

export default function ErrorPage() {
  return (
    <div className="my-5 py-5" dir="rtl">
      <div className="text-center">
        <img
          src={sadFAce}
          alt="404"
          width="100%"
          style={{ maxWidth: "220px" }}
        />
        <h2 className="display-1 text-center mt-4 fw-bold text-custom-blue">
          404
        </h2>
        <p className="pt-2 pb-3 fs-1 text-muted px-2">
          ئه‌و مالپه‌رێ تو لێ دگه‌ری نینه‌
        </p>
        <Link to="/" className="btn btn-lg btn-custom-orange rounded-0">
          زڤرین بو مالپه‌رێ سه‌ره‌کی
        </Link>
      </div>
    </div>
  );
}
