import ItemsSection from "../components/clothesComp/ItemSection";
import ItemDisplay from "../components/clothesComp/ItemDisplay";

import bigSize from "../images/clothesImages/women/clothes/big_size.jpg";
import bloz from "../images/clothesImages/women/clothes/bloz.jpg";
import dress from "../images/clothesImages/women/clothes/dress.jpg";
import hijab from "../images/clothesImages/women/clothes/hijab.jpg";
import jakit from "../images/clothesImages/women/clothes/jakit.jpg";
import jakit_drej from "../images/clothesImages/women/clothes/jakit_drej.jpg";
import penteron from "../images/clothesImages/women/clothes/penteron.jpg";
import privateClothes from "../images/clothesImages/women/clothes/private.jpg";
import qemis from "../images/clothesImages/women/clothes/qemis.jpg";
import strej from "../images/clothesImages/women/clothes/strej.jpg";
import tenore from "../images/clothesImages/women/clothes/tenore.jpg";
import tishirt from "../images/clothesImages/women/clothes/tishirt.jpg";
import tracksuit from "../images/clothesImages/women/clothes/tracksuit.jpg";

import flat from "../images/clothesImages/women/shoes/flat.jpg";
import nael from "../images/clothesImages/women/shoes/nael.jpg";
import pilavjmk from "../images/clothesImages/women/shoes/pilavjmk.jpg";
import puta from "../images/clothesImages/women/shoes/puta.jpg";
import sport from "../images/clothesImages/women/shoes/sport.jpg";

import accessories from "../images/clothesImages/women/accessories/accessories.jpg";
import glasses from "../images/clothesImages/women/accessories/glasses.jpg";
import kemer from "../images/clothesImages/women/accessories/kemer.jpg";
import shal from "../images/clothesImages/women/accessories/shal.jpg";
import sjefqe from "../images/clothesImages/women/accessories/sjefqe.jpg";
import wallet from "../images/clothesImages/women/accessories/wallet.jpg";
import watch from "../images/clothesImages/women/accessories/watch.jpg";

import backpack from "../images/clothesImages/women/bags/backpack.jpg";
import hand_bag from "../images/clothesImages/women/bags/hand_bag.jpg";
import jizdan from "../images/clothesImages/women/bags/jizdan.jpg";
import navteng_beg from "../images/clothesImages/women/bags/navteng_beg.jpg";
import postachi_beg from "../images/clothesImages/women/bags/postachi_beg.jpg";
import shoulder_bag from "../images/clothesImages/women/bags/shoulder_bag.jpg";
import travel_beg from "../images/clothesImages/women/bags/travel_beg.jpg";

import Sprotection from "../images/clothesImages/women/cosmetics/Sprotection.jpg";
import haircare from "../images/clothesImages/women/cosmetics/haircare.jpg";
import lipstick from "../images/clothesImages/women/cosmetics/lipstick.jpg";
import makeup from "../images/clothesImages/women/cosmetics/makeup.jpg";
import oral_health from "../images/clothesImages/women/cosmetics/oral_health.jpg";
import prefume from "../images/clothesImages/women/cosmetics/prefume.jpg";
import skin_makeup from "../images/clothesImages/women/cosmetics/skin_makeup.jpg";
import skincare from "../images/clothesImages/women/cosmetics/skincare.jpg";
import body_care from "../images/clothesImages/women/cosmetics/body_care.jpg";

import jog_shoes from "../images/clothesImages/women/sport/jog_shoes.jpg";
import protein from "../images/clothesImages/women/sport/protein.jpg";
import sport_bags from "../images/clothesImages/women/sport/sport_bags.jpg";
import sport_clothes from "../images/clothesImages/women/sport/sport_clothes.jpg";
import sport_equipment from "../images/clothesImages/women/sport/sport_equipment.jpg";
import sport_shoes from "../images/clothesImages/women/sport/sport_shoes.jpg";
import sport_tracksuit from "../images/clothesImages/women/sport/tracksuit.jpg";

export default function WomenClothes() {
  return (
    <main className="col-lg-10 col-8">
      <h2 className="display-4 mb-5">ستایلێن ئافرەتان</h2>
      <ItemsSection sectName="جلکێن ئافرەتان" scrollLink="womenCloth">
        <ItemDisplay
          imgSrc={dress}
          name="کراس"
          linkSrc="https://www.trendyol.com/kadin-elbise-x-g1-c56"
        />
        <ItemDisplay
          imgSrc={bloz}
          name="بلوز"
          linkSrc="https://www.trendyol.com/kadin-sweatshirt-x-g1-c1179"
        />
        <ItemDisplay
          imgSrc={tishirt}
          name="تیشێرت"
          linkSrc="https://www.trendyol.com/kadin-t-shirt-x-g1-c73"
        />
        <ItemDisplay
          imgSrc={penteron}
          name="پەنتەڕون"
          linkSrc="https://www.trendyol.com/kadin-pantolon-x-g1-c70"
        />
        <ItemDisplay
          imgSrc={qemis}
          name="قەمیس"
          linkSrc="https://www.trendyol.com/kadin-gomlek-x-g1-c75"
        />
        <ItemDisplay
          imgSrc={jakit}
          name="چاکیت"
          linkSrc="https://www.trendyol.com/kadin-mont-x-g1-c118"
        />

        <ItemDisplay
          imgSrc={jakit_drej}
          name="چاکیتێن درێژ"
          linkSrc="https://www.trendyol.com/kadin-trenckot-x-g1-c79"
        />
        <ItemDisplay
          imgSrc={tracksuit}
          name="تەرەکسوت"
          linkSrc="https://www.trendyol.com/kadin-esofman-x-g1-c1049"
        />
        <ItemDisplay
          imgSrc={strej}
          name="سترێج"
          linkSrc="https://www.trendyol.com/kadin-tayt-x-g1-c121"
        />
        <ItemDisplay
          imgSrc={tenore}
          name="تەنورە"
          linkSrc="https://www.trendyol.com/etek-x-c69"
        />
        <ItemDisplay
          imgSrc={hijab}
          name="حیجاب"
          linkSrc="https://www.trendyol.com/kadin-tesettur-giyim-x-g1-c81"
        />
        <ItemDisplay
          imgSrc={bigSize}
          name="سایزێن مەزن"
          linkSrc="https://www.trendyol.com/kadin-buyuk-beden-x-g1-c80"
        />
        <ItemDisplay
          imgSrc={privateClothes}
          name="جلکێن تایبەت"
          linkSrc="https://www.trendyol.com/kadin-ic-giyim-x-g1-c64"
        />
      </ItemsSection>
      <ItemsSection sectName="پێلاڤ" scrollLink="womenShoes">
        <ItemDisplay
          imgSrc={puta}
          name="پوتە"
          linkSrc="https://www.trendyol.com/kadin-bot-ve-cizme-x-g1-c1351"
        />
        <ItemDisplay
          imgSrc={sport}
          name="پێلاڤێن وەرزشی"
          linkSrc="https://www.trendyol.com/kadin-spor-ayakkabi-x-g1-c109"
        />
        <ItemDisplay
          imgSrc={pilavjmk}
          name="پێلاڤێن ب چمک"
          linkSrc="https://www.trendyol.com/kadin-topuklu-ayakkabi-x-g1-c107"
        />
        <ItemDisplay
          imgSrc={nael}
          name="نەعال"
          linkSrc="https://www.trendyol.com/kadin-ev-botu-terligi-x-g1-c104206"
        />
        <ItemDisplay
          imgSrc={flat}
          name="پێلاڤێن فلات"
          linkSrc="https://www.trendyol.com/kadin-gunluk-ayakkabi-x-g1-c1352"
        />
      </ItemsSection>

      <ItemsSection
        sectName="ئێکسسوارات و بەرچافک"
        scrollLink="womenAccessories"
      >
        <ItemDisplay
          imgSrc={watch}
          name="دەمژمێر"
          linkSrc="https://www.trendyol.com/kadin-saat-x-g1-c34"
        />
        <ItemDisplay
          imgSrc={accessories}
          name="ئێکسسوارات"
          linkSrc="https://www.trendyol.com/kadin-taki-mucevher-x-g1-c28"
        />
        <ItemDisplay
          imgSrc={glasses}
          name="بەرچافک"
          linkSrc="https://www.trendyol.com/kadin-gunes-gozlugu-x-g1-c105"
        />
        <ItemDisplay
          imgSrc={wallet}
          name="جزدان"
          linkSrc="https://www.trendyol.com/kadin-cuzdan-x-g1-c1032"
        />
        <ItemDisplay
          imgSrc={kemer}
          name="کەمەر"
          linkSrc="https://www.trendyol.com/kadin-kemer-x-g1-c1093"
        />
        <ItemDisplay
          imgSrc={shal}
          name="شال و راپێچ"
          linkSrc="https://www.trendyol.com/kadin-sal-ve-fular-x-g1-c101433"
        />
        <ItemDisplay
          imgSrc={sjefqe}
          name="شەفقە"
          linkSrc="https://www.trendyol.com/kadin-sapka-x-g1-c1181"
        />
      </ItemsSection>

      <ItemsSection sectName="چانتە" scrollLink="womenBag">
        <ItemDisplay
          imgSrc={shoulder_bag}
          name="چانتێن ملی"
          linkSrc="https://www.trendyol.com/kadin-omuz-cantasi-x-g1-c101465"
        />
        <ItemDisplay
          imgSrc={jizdan}
          name="جزدان"
          linkSrc="https://www.trendyol.com/sr?q=kad%C4%B1n%20portf%C3%B6y&qt=kad%C4%B1n%20portf%C3%B6y&st=kad%25C4%25B1n%2520port&os=1"
        />
        <ItemDisplay
          imgSrc={hand_bag}
          name="چانتا دەستی"
          linkSrc="https://www.trendyol.com/kadin-el-cantasi-x-g1-c104145"
        />
        <ItemDisplay
          imgSrc={postachi_beg}
          name="چانتا پوستەچی"
          linkSrc="https://www.trendyol.com/kadin-postaci-canta-x-g1-c1152"
        />
        <ItemDisplay
          imgSrc={navteng_beg}
          name="چانتا نافتەنگێ"
          linkSrc="https://www.trendyol.com/kadin-bel-cantasi-x-g1-c104144"
        />
        <ItemDisplay
          imgSrc={travel_beg}
          name="چانتا گەشتا"
          linkSrc="https://www.trendyol.com/kadin-valiz-x-g1-c1202"
        />
        <ItemDisplay
          imgSrc={backpack}
          name="چانتا پشتێ"
          linkSrc="https://www.trendyol.com/kadin-sirt-cantasi-x-g1-c115"
        />
      </ItemsSection>
      <ItemsSection sectName="کوزماتیک و مێک ئاپ" scrollLink="womenCosmetics">
        <ItemDisplay
          imgSrc={prefume}
          name="گولاڤ و ریحە"
          linkSrc="https://www.trendyol.com/kadin-parfum-ve-deodorant-x-g1-c103717"
        />
        <ItemDisplay
          imgSrc={makeup}
          name="مکیاجێ چاڤان"
          linkSrc="https://www.trendyol.com/sr/kadin-goz-makyaji-x-g1-c1347"
        />
        <ItemDisplay
          imgSrc={skincare}
          name="چاڤدانا پیستی"
          linkSrc="https://www.trendyol.com/cilt-bakimi-x-c85"
        />
        <ItemDisplay
          imgSrc={skin_makeup}
          name="مکیاجێ پیستی"
          linkSrc="https://www.trendyol.com/ten-makyaji-x-c1348"
        />
        <ItemDisplay
          imgSrc={lipstick}
          name="سوراڤ"
          linkSrc="https://www.trendyol.com/dudak-makyaji-x-c1346"
        />
        <ItemDisplay
          imgSrc={haircare}
          name="چاڤدانا پرچی"
          linkSrc="https://www.trendyol.com/kadin-sac-bakimi-x-g1-c87"
        />
        <ItemDisplay
          imgSrc={Sprotection}
          name="ساخلەمیا سێکسی"
          linkSrc="https://www.trendyol.com/cinsel-saglik-urunleri-x-c101408"
        />
        <ItemDisplay
          imgSrc={body_care}
          name="چاڤدێریا لەشی"
          linkSrc="https://www.trendyol.com/kadin-vucut-bakimi-x-g1-c1204"
        />
        <ItemDisplay
          imgSrc={oral_health}
          name="چاڤدێریا دەڤ و ددانا"
          linkSrc="https://www.trendyol.com/kadin-agiz-bakim-x-g1-c101396"
        />
      </ItemsSection>
      <ItemsSection sectName="وەرزشی" scrollLink="womenSport">
        <ItemDisplay
          imgSrc={sport_shoes}
          name="پێلاڤێن وەرزشی"
          linkSrc="https://www.trendyol.com/kadin-spor-ayakkabi-x-g1-c109"
        />
        <ItemDisplay
          imgSrc={sport_tracksuit}
          name="تەرەکسوت"
          linkSrc="https://www.trendyol.com/kadin-esofman-x-g1-c1049"
        />
        <ItemDisplay
          imgSrc={sport_bags}
          name="چانتێن وەرزشی"
          linkSrc="https://www.trendyol.com/kadin-spor-cantasi-x-g1-c1174"
        />
        <ItemDisplay
          imgSrc={sport_clothes}
          name="جلوبەرگێن وەرزشی"
          linkSrc="https://www.trendyol.com/sr?q=kad%C4%B1n%20outdoor%20giyim&qt=kad%C4%B1n%20outdoor%20giyim&st=kad%25C4%25B1n%2520ou&os=1"
        />
        <ItemDisplay
          imgSrc={jog_shoes}
          name="پێاڤێن هەروێلێ"
          linkSrc="https://www.trendyol.com/kadin-kosu-ayakkabisi-x-g1-c101426"
        />
        <ItemDisplay
          imgSrc={sport_equipment}
          name="ئامیرێن وەرزشی"
          linkSrc="https://www.trendyol.com/kadin-spor-aletleri-x-g1-c104192"
        />
        <ItemDisplay
          imgSrc={protein}
          name="پرۆتین و فیتامین"
          linkSrc="https://www.trendyol.com/sporcu-besinleri-supplement-x-c105131"
        />
      </ItemsSection>
    </main>
  );
}

// <ItemDisplay imgSrc={testImg} name="" linkSrc="" />
