import MenImg from "../images/man.jpg";
import WomenImg from "../images/women.jpg";
import KidImg from "../images/kid.jpg";

import Flickity from "react-flickity-component";
import React from "react";
import { Link } from "react-router-dom";

export default function ProductImgSection() {
  const flickityOptions = {
    initialIndex: 1,
    wrapAround: true,
    autoPlay: 5000,
    prevNextButtons: false,
    pageDots: false,
    cellAlign: "right",
  };

  function MainCarousel() {
    return (
      <Flickity
        className={"carousel"} // default ''
        elementType={"div"} // default 'div'
        options={flickityOptions} // takes flickity options {}
        disableImagesLoaded={false} // default false
        reloadOnUpdate // default false
        static // default false
      >
        <CarouselCell productType="زاروک" imgSrc={KidImg} linkSrc="kids" />
        <CarouselCell productType="ئافرەت" imgSrc={WomenImg} linkSrc="women" />
        <CarouselCell productType="زەلام" imgSrc={MenImg} linkSrc="men" />
      </Flickity>
    );
  }

  const ImageSectionConatiner = ({ productType, imgSrc, linkSrc }) => {
    return (
      <div className="col-4 position-relative px-0 mx-0">
        <SylizedImage
          productType={productType}
          imgSrc={imgSrc}
          linkSrc={linkSrc}
        />
      </div>
    );
  };

  const CarouselCell = ({ productType, imgSrc, linkSrc }) => {
    return (
      <div className="col-md-6 col-12">
        <SylizedImage
          productType={productType}
          imgSrc={imgSrc}
          linkSrc={linkSrc}
        />
      </div>
    );
  };

  const SylizedImage = ({ productType, imgSrc, linkSrc }) => (
    <Link to={`clothes/${linkSrc}`} className="d-block custom-card-image-links">
      <img
        src={imgSrc}
        className="w-100 object-fit-cover"
        style={{ height: "470px" }}
        alt={productType}
      />
      <div className="position-absolute top-0 bottom-0 w-100 d-flex flex-column justify-content-between">
        <div className="h-100"></div>

        <h3 className="h-100 mb-0 display-2 text-center text-white fw-bold">
          {productType}
        </h3>

        <div className="h-100 align-self-center d-flex">
          <div className="text-dark bg-light m-auto mb-4  rounded-0 px-5 py-3 ">
            {productType}
          </div>
        </div>
      </div>
    </Link>
  );

  return (
    <React.Fragment>
      <section className="row px-0 mx-0 d-lg-flex d-none">
        <ImageSectionConatiner
          productType="زاروک"
          imgSrc={KidImg}
          linkSrc="kids"
        />
        <ImageSectionConatiner
          productType="ئافرەت"
          imgSrc={WomenImg}
          linkSrc="women"
        />
        <ImageSectionConatiner
          productType="زەلام"
          imgSrc={MenImg}
          linkSrc="men"
        />
      </section>

      <section className="overflow-hidden d-lg-none d-block">
        <MainCarousel />
      </section>
    </React.Fragment>
  );
}
