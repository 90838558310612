import { Route, Routes, useLocation } from "react-router-dom";
import Footer from "./components/footer";
import SiteHeader from "./components/header";
import Home from "./pages/home";
import Brands from "./pages/brands";
import AboutUs from "./pages/aboutus";
import { useEffect } from "react";
import MenClothes from "./pages/MenClothes";
import Clothes from "./pages/clothes";
import WomenClothes from "./pages/WomenClothes.";
import KidsClothes from "./pages/Kids";
import { getTL } from "./services/getTL";
import ErrorPage from "./pages/error";

function App() {
  getTL().then((value) =>
    sessionStorage.setItem("TLValue", value[0].price || 30)
  );

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
  }, [pathname]);

  return (
    <div>
      <SiteHeader />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/brands" element={<Brands />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/clothes" element={<Clothes />}>
          <Route path="men" element={<MenClothes />} />
          <Route path="women" element={<WomenClothes />} />
          <Route path="kids" element={<KidsClothes />} />
        </Route>
        <Route path="/*" element={<ErrorPage />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
