import axios from "axios";

export async function getTL() {
  try {
    const url = "https://api.mubabazar.net/api/v1/currency";
    const data = await axios.get(url);

    return data.data;
  } catch (error) {
    console.log(error);
  }
}
