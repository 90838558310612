import mubaLogo from "../images/logo.webp";
import fblogo from "../images/icons/facebook.webp";
import snap from "../images/icons/snap.webp";
import insta from "../images/icons/intsta.webp";
import youtube from "../images/icons/youtube.svg";
import { Link } from "react-router-dom";

import ExchangeFooter from "./exchangeFooter";

export default function Footer() {
  const LogoImg = ({ itemName, imgSrc, linkSrc }) => (
    <a href={linkSrc} className="mx-1">
      <img
        height="26"
        width="26"
        src={imgSrc}
        alt={`link-to-${itemName}`}
        aria-label={itemName}
      />
    </a>
  );

  return (
    <footer className="bg-custom-blue">
      <div
        dir="rtl"
        className="container row m-auto pb-3"
        style={{ paddingBlock: "6rem 0" }}
      >
        <section className="col-lg-3 col-12 pb-4">
          <img src={mubaLogo} alt="MubaLogo" />
          <div className="mt-3">
            <LogoImg
              itemName="youtube"
              imgSrc={youtube}
              linkSrc="https://www.youtube.com/@mubabazar723"
            />
            <LogoImg
              itemName="snapchat"
              imgSrc={snap}
              linkSrc="https://www.snapchat.com/add/mubabazar"
            />
            <LogoImg
              itemName="instagram"
              imgSrc={insta}
              linkSrc="https://www.instagram.com/mubabazar/"
            />
            <LogoImg
              itemName="facebook"
              imgSrc={fblogo}
              linkSrc="https://www.facebook.com/mubabazar"
            />
          </div>
        </section>
        <section className="col-lg-3 col-sm-4 col-6 pb-sm-0 pb-4">
          <h4 className="text-white mb-sm-2 mb-4">مالپه‌ر</h4>
          <p className="mb-sm-2">
            <Link
              to="/"
              className="custom-text-gray-to-orange text-decoration-none fw-4"
            >
              سەرەکی
            </Link>
          </p>

          <p className="mb-sm-2">
            <Link
              to="/brands"
              className="mb-2 custom-text-gray-to-orange text-decoration-none fw-4"
            >
              براند
            </Link>
          </p>

          <p className="mb-sm-2">
            <Link
              to="/aboutus"
              className="mb-2 custom-text-gray-to-orange text-decoration-none fw-4"
            >
              سەبارەت مە
            </Link>
          </p>
        </section>
        <section className="col-lg-3 col-sm-4 col-6 pb-sm-0 pb-4">
          <h4 className="text-white mb-sm-2 mb-4"> جۆرێن جلکا </h4>
          <p className="mb-sm-2">
            <Link
              to="clothes/men"
              className="custom-text-gray-to-orange text-decoration-none fw-4"
            >
              زەلام
            </Link>
          </p>
          <p className="mb-sm-2">
            <Link
              to="clothes/women"
              className="custom-text-gray-to-orange text-decoration-none fw-4"
            >
              ئافرەت
            </Link>
          </p>
          <p className="mb-sm-2">
            <Link
              to="clothes/kids"
              className="custom-text-gray-to-orange text-decoration-none fw-4"
            >
              زاروک
            </Link>
          </p>
        </section>

        <section
          className="col-lg-3 col-sm-4 col-12 d-flex flex-column align-items-end"
          dir="ltr"
        >
          <h4 className="text-white mb-sm-2 mb-4">په‌یوه‌ندی</h4>
          <p className="custom-text-gray fw-light mb-1">+964 750 570 6060</p>
          <p className="custom-text-gray fw-light mb-1">mubabazar@gmail.com</p>
        </section>
      </div>
      <ExchangeFooter />

      <div className="container pb-4">
        <hr className="border-light m-0 pb-4" />
        <p className="text-white fw-lighter m-0">© 2024 by Muba Tech.</p>
      </div>
    </footer>
  );
}
