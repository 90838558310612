export default function ItemsSection({ sectName, children, scrollLink }) {
  return (
    <div className="mt-5" id={scrollLink}>
      <h2 className="display-5 py-1 bg-white d-md-block d-none">{sectName}</h2>
      <h2
        className="display-5 position-sticky top-0 py-1 bg-white d-md-none d-block"
        style={{ zIndex: "1" }}
      >
        {sectName}
      </h2>

      <div className="row">{children}</div>
      <hr className="border-dark border-opacity-50" />
    </div>
  );
}
