import cosmetics from "../images/images/cosmetics.jpg";
import watches from "../images/images/watches.jpg";
import perfume from "../images/images/perfume.jpg";

import ship from "../images/icons/ship.svg";
import price from "../images/icons/price.svg";
import service from "../images/icons/service.svg";

import ProductImgSection from "./ProductImgSection";

export default function ProductSection() {
  const OtherProducts = () => {
    const Item = ({ imgSrc, itemName }) => (
      <div className="col-lg-4 col-md-6 col-12 d-flex align-items-center justify-content-lg-center justify-content-start py-lg-0 py-md-2 py-3">
        <img src={imgSrc} style={{ height: "25px" }} alt="descIcon" />
        <span className="pe-3 fs-5"> {itemName}</span>
      </div>
    );

    return (
      <div
        className="container-lg row border-bottom py-4 mx-auto"
        style={{ direction: "rtl" }}
      >
        <Item imgSrc={price} itemName="بهایێن بێ رکابەر" />

        <Item imgSrc={ship} itemName="لەزاتیەکا بێ سنور" />
        <Item imgSrc={service} itemName="خزمەتەکا بێ راوەستیان" />
      </div>
    );
  };

  const OtherProdImg = ({ className, prodLink, imgSrc, productname }) => {
    return (
      <a
        href={prodLink}
        className={`py-3 col-lg-4 col-md-6 col-12 position-relative custom-card-image-links ${className}`}
        style={{ height: "240px" }}
      >
        <img
          src={imgSrc}
          className="object-fit-cover w-100 h-100"
          alt={productname}
        />
        <div className="position-absolute d-flex m-auto mb-5 bottom-0 start-0 end-0">
          <h2 dir="rtl" className="m-auto">
            {productname}
          </h2>
        </div>
      </a>
    );
  };

  return (
    <div>
      <ProductImgSection />

      <section className="px-lg-0 px-2">
        <OtherProducts />
      </section>

      <section className="container">
        <div className="row py-3">
          <OtherProdImg
            className="text-white"
            imgSrc={cosmetics}
            prodLink="https://www.trendyol.com/butik/liste/11/kozmetik"
            productname="کوزماتیک"
          />
          <OtherProdImg
            className="text-white"
            prodLink="https://www.trendyol.com/sr?q=parf%C3%BCm&qt=parf%C3%BCm&st=parf%C3%BCm&os=1&sk=1"
            imgSrc={perfume}
            productname="گولاڤ"
          />
          <OtherProdImg
            className="text-white"
            prodLink="https://www.trendyol.com/butik/liste/10/saat-aksesuar"
            imgSrc={watches}
            productname="دەمژمێر و ئێکسسوارات"
          />
        </div>
      </section>
    </div>
  );
}
