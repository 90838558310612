export default function AboutMuba() {
  return (
    <div dir="rtl" className="bg-custom-blue text-white row mx-0 ">
      <section className="container py-5 col-lg-6 col-md-8 col-10">
        <h1 className="text-custom-orange mb-4">دگەل موبا بازار</h1>
        <p>
          هەر تشتەکێ تە ژ وەلاتێ تورکیا بڤێت ب زیترین دەم دێ گەهیتە بەر دەستێ تە
          ب بهایەکێ گونجای د جێوازین د گوهرینا دولاری دا و د بێ رکابەرین د
          سەرەدەریێ دگەل بکرێن خوشتڤی
        </p>
        <p>ل نێزیک چاڤەرێی خزمەتێن نە چاڤەرێکری بن</p>
      </section>
    </div>
  );
}
